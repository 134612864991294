import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// setting up redux
// import { createStore, applyMiddleware, compose } from "redux";
// connection redux with react
import { Provider } from "react-redux";
// importing root reducer
// import rootReducer from "./Store";
// importing redux thunk for feching data
// import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { store } from "./Utils/GlobalVariable";
import ErrorBoundary from "./Components/ErrorBoundry/ErrorBoundary";

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));
// const store = createStore(rootReducer, applyMiddleware(thunk));

const history = createBrowserHistory();

const handleTryAgain = () => {
  window.location = "/";
};

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <ErrorBoundary handleTryAgain={handleTryAgain}>
        <App />
      </ErrorBoundary>
    </Provider>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.register();
