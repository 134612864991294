import React, {useCallback, useEffect, useState} from 'react'
import {
  getPaymentStatusDecentro,
  payByDecentroUpiLink,
} from '../InfluencerProfile/CheckoutScreen'
import {Container} from '@material-ui/core'

const DecentroUpliLink = ({
  setShowDecentroUpiLink,
  getPayload,
  setDecentroUpiLinkPayload,
}) => {
  const [transactionUrl, setTransactionUrl] = useState('')
  const [isPaymentFailed, setIsPaymentFailed] = useState(false)
  //   const [open, setOpen] = useState(false)

  const pollPaymentStatus = useCallback(
    (params) => {
      const intervalTime = 30000
      const retry = 3
      let timer = intervalTime
      const intervalId = setInterval(async () => {
        const payload = {
          orderId: params.ORDER_ID,
          contentPurchaseType: params.CONTENT_PURCHASE_TYPE,
          returnUrl: params.REDIRECT_URL,
        }
        const data = await getPaymentStatusDecentro(payload)
        if (data.data.status) {
          window.location.href = 'https://official.me' + data.data.return_url
          setShowDecentroUpiLink(false)
          setDecentroUpiLinkPayload({})
          return
        }

        if (timer >= intervalTime * retry) {
          setIsPaymentFailed(true)
          setTimeout(() => {
            setShowDecentroUpiLink(false)
            setDecentroUpiLinkPayload({})
          }, 10000)
          return
        } else {
          timer += intervalTime
        }
      }, intervalTime)

      setTimeout(() => {
        clearInterval(intervalId)
      }, intervalTime * retry)
    },
    [setDecentroUpiLinkPayload, setShowDecentroUpiLink]
  )

  const fetchData = useCallback(async () => {
    try {
      const data = await payByDecentroUpiLink(getPayload())
      setTransactionUrl(data.data.transaction_url)
      pollPaymentStatus(data.data.params)
      //   setOpen(true)
    } catch (e) {
      console.log({e})
    }
  }, [getPayload, pollPaymentStatus])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    console.log({transactionUrl})
    if (transactionUrl) {
      window.location.href = transactionUrl
    }
  }, [transactionUrl])

  //   const handleClose = () => {
  //     if (window.confirm('Are you sure?')) {
  //       setOpen(false)
  //       setShowDecentroUpiLink(false)
  //       setDecentroUpiLinkPayload({})
  //     } else {
  //       console.log('nothing happened')
  //     }
  //   }

  return <Container>{isPaymentFailed && <h4>Payment Failed</h4>}</Container>
}
export default DecentroUpliLink
