import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getPaymentStatusDecentro, payByDecentro } from '../InfluencerProfile/CheckoutScreen';


const DecentroPayment = ({setshowDecentroPayment, getPayload, setdecentroPayload}) => {
    const [showPaymentStatus, setshowPaymentStatus] = useState(false);

    useEffect(async() => {
        var data = await payByDecentro(getPayload())
        console.log("response", data)
        pollPaymentStatus(data.data.params);
    }, []);
    
    const pollPaymentStatus = (params) => {
        var intervalTime = 30000
        var retry = 3
        var timer = intervalTime
        var intervalId = setInterval(async() => {
            var data = await getPaymentStatus(params.ORDER_ID, params.REDIRECT_URL, params.CONTENT_PURCHASE_TYPE)
            console.log("PS decentro STATUS", data)
            if (data.data.status) {
                window.location.href = "https://official.me" + data.data.return_url
                setshowDecentroPayment(false)
                setdecentroPayload({})
                return
            }
            
            if (timer >= intervalTime*retry) {
                setshowPaymentStatus(true);
                setTimeout(()=> {
                    setshowDecentroPayment(false);
                    setdecentroPayload({})
                }, 10000)
                return
            } else {
                timer += intervalTime
            }
        }, intervalTime);

        setTimeout(() => {
            clearInterval(intervalId);
        }, intervalTime * retry)
    }

    const getPaymentStatus = (orderId, returnUrl, purchaseType) => {
        var data = {
            orderId: orderId,
            contentPurchaseType: purchaseType, 
            returnUrl: returnUrl
        }
        return getPaymentStatusDecentro(data)
    }

    return (
        <Container>
            {!showPaymentStatus ? <>
                <h4> Payment Request Sent</h4>
                <p>Open Upi App and approve request</p>
                <p>Please complete the payment within 3 minutes</p>
            </>: 
            <>
            <h4>Payment Failed</h4>
            </>
            }
        </Container>
    )
 };
export default DecentroPayment;