import axios from 'axios'
import {BASE_URL} from '../Utils/GlobalVariable'

export const usernameCheckApi = async (username) => {
  let checkUsername = await axios.post(`${BASE_URL}/unamecheck`, {
    username: username,
  })
  return checkUsername
}

export const signupApi = async (userData) => {
  let signup = await axios.post(`${BASE_URL}/signup`, {
    ...userData,
  })
  return signup
}

export const forgotPasswordApi = async (email, influencerName) => {
  let data = await axios.post(`${BASE_URL}/forgot-password`, {
    email,
    influencerName,
  })
  return data
}

export const loginApi = async (userData) => {
  let data = await axios.post(`${BASE_URL}/login`, {
    ...userData,
  })
  return data
}

export const resetPasswordApi = async (token, password) => {
  let data = await axios.post(`${BASE_URL}/reset-password/${token}`, {
    password: password,
  })
  return data
}

export const checkApiStatus = async () => {
  const response = await axios.get(`${BASE_URL}/api/status`)
  return response
}
