import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../Store";

// if port is 3003 in .env file then its production and if 3007 then its stage for more info check GlobalVariable.js and .env file

// change environment
let environment;

if (process.env.REACT_APP_PORT === "3007") {
  environment = "dev";
}
if (process.env.REACT_APP_PORT === "3003") {
  environment = "prod";
}

// all varialbles
export let BASE_URL;
export let razorpayKey;
export let stripeKey;
export let PRESENSE_SERVER_URL;
export let Domain_Name;
export let applicationServerPublicKey;
export let store;
export let firebaseConfig;
export let SafexpayUrl;
export let fireBaseShortURL;
export let AppId = process.env.REACT_APP_APP_ID;
export let FreshChatToken = process.env.REACT_APP_FRESH_CHAT_TOKEN;
export let FreshChatHost = process.env.REACT_APP_FRESH_CHAT_HOST;
export let API_AUTH_KEY = process.env.REACT_APP_API_AUTH_KEY;
export let FRESHCHAT_SRC = process.env.REACT_APP_FRESHCHAT_SRC;
export let PAYPAL_SRC = process.env.REACT_APP_PAYPAL_SRC;
export let RAZORPAY_SRC = process.env.REACT_APP_RAZORPAY_SRC;

if (environment === "dev") {
  BASE_URL = process.env.REACT_APP_DEV_BASE_URL;
  // BASE_URL = 'https://d56d-2409-4051-17-12f2-3924-3acb-c579-62c7.ngrok.io';
  // BASE_URL = "http://localhost:4200";
  // BASE_URL = "https://api.official.me";
  razorpayKey = process.env.REACT_APP_DEV_RAZORPAY_KEY;
  stripeKey = process.env.REACT_APP_DEV_STRIPE_KEY;
  PRESENSE_SERVER_URL = process.env.REACT_APP_DEV_PRESENSE_SERVER_URL;
  Domain_Name = process.env.REACT_APP_DEV_DOMAIN_NAME;
  applicationServerPublicKey =
    process.env.REACT_APP_DEV_APPLICATION_SERVER_PUBLIC_KEY;
  SafexpayUrl = process.env.REACT_APP_DEV_SAFEXPAY_URL;
  const composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));
  fireBaseShortURL = process.env.REACT_APP_DEV_FIREBASE_SHORT_URL;
  firebaseConfig = {
    apiKey: "AIzaSyBLgbXaXqJHDvgGzzYkRFqW1VkaSwBcN_U",
    authDomain: "inssaclub-chat.firebaseapp.com",
    databaseURL:
      "https://inssaclub-chat-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "inssaclub-chat",
    storageBucket: "inssaclub-chat.appspot.com",
    messagingSenderId: "963647848486",
    appId: "1:963647848486:web:c6b348066561cc499be6e7",
    measurementId: "G-B9DG61RYHR",
  };
}

if (environment === "prod") {
  BASE_URL = process.env.REACT_APP_PROD_BASE_URL;
  razorpayKey = process.env.REACT_APP_PROD_RAZORPAY_KEY;
  stripeKey = process.env.REACT_APP_PROD_STRIPE_KEY;
  PRESENSE_SERVER_URL = process.env.REACT_APP_PROD_PRESENSE_SERVER_URL;
  Domain_Name = process.env.REACT_APP_PROD_DOMAIN_NAME;
  applicationServerPublicKey =
    process.env.REACT_APP_PROD_APPLICATION_SERVER_PUBLIC_KEY;
  SafexpayUrl = process.env.REACT_APP_PROD_SAFEXPAY_URL;
  store = createStore(rootReducer, applyMiddleware(thunk));
  fireBaseShortURL = process.env.REACT_APP_PROD_FIREBASE_SHORT_URL;
  firebaseConfig = {
    apiKey: "AIzaSyDrGO6wg0Xzqfe9ngw_64m4TY5C7gYxhNM",
    authDomain: "influencer-app-306509.firebaseapp.com",
    databaseURL: "https://influencer-app-306509-default-rtdb.firebaseio.com",
    projectId: "influencer-app-306509",
    storageBucket: "influencer-app-306509.appspot.com",
    messagingSenderId: "398837057739",
    appId: "1:398837057739:web:cfac3761c00ef244f0334e",
    measurementId: "G-LTM7KQEFN9",
  };
}
