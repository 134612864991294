const userInitialState = {
  userType: "",
  userProfileImage: "",
  userBio: "",
  email: "",
  password: "",
  username: "",
  name: "",
  instagram: "",
  facebook: "",
  twitter: "",
  linkedin: "",
  addMoreLinks: [],
  checkUsername: {},
  createpagecheck: false,
  isuserAlreadyRegistered: false,
  social: false,
  accessToken: "",
  refreshToken: "",
  signupCompleted: false,
  isLoggedIn: false,
  userLoggedIn: false,
  influencerPageLoadedData: {
    username: "",
    posts: [],
  },
  subscriptionPlansOfCurrentInfluencer: [],
};

const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case "TOGGLE_NOTIFICATION_STATUS":
      return {
        ...state,
        influencerPageLoadedData: {
          ...state.influencerPageLoadedData,
          notificationstatus: action.payload,
        },
      };
    case "CHECK_USERNAME":
      return {
        ...state,
        ...action.payload,
      };
    case "CREATE_PAGE_DATA":
      let newState = {
        ...state,
        ...action.payload.userData,
        createpagecheck: true,
      };
      return {
        ...newState,
      };
    case "USER_SIGNUP":
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        ...action.payload.savedUserData,
        signupCompleted: true,
        isLoggedIn: action.payload.savedUserData.userType === "agency",
      };
    case "USER_LOGIN":
      return {
        ...state,
        isLoggedIn: true,
        ...action.payload,
      };
    case "USERNAME_DISPATCH":
      return {
        ...state,
        username: action.payload,
      };
    case "REDIRECT_FROM_REGISTER_TO_LOGIN":
      return {
        ...state,
        isuserAlreadyRegistered: true,
      };
    case "REDIRECT_FROM_WELCOME_TO_DASHBOARD":
      return {
        ...state,
        isLoggedIn: true,
      };
    case "FORGET_PASSWORD":
      return {
        ...state,
      };
    case "DASHBOARD_DETAILS_UPDATE":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "PUT_DATA_IN_REDUCER":
      return {
        ...state,
        userProfileImage: action.payload,
      };
    case "USER_DASHBOARD_UPDATES":
      return {
        ...state,
        ...action.payload,
      };
    case "INFLUENCER_PAGE_LOADED":
      return {
        ...state,
        influencerPageLoadedData: {
          ...state.influencerPageLoadedData,
          ...action.payload,
          posts: state.influencerPageLoadedData.posts && [...state.influencerPageLoadedData.posts],
        },
      };

    case "SUBSCRIPTION_PLANS_OF_CURRENT_INFLUENCER":
      return {
        ...state,
        subscriptionPlansOfCurrentInfluencer: [...action.payload],
      };
    case "LOCALSTORAGE_STATE_UPDATE":
      return {
        ...action.payload,
      };
    case "VIEW_UPDATE":
      let postsUpdate = [];
      if (state.influencerPageLoadedData.posts) {
        postsUpdate = [...state.influencerPageLoadedData.posts];
        postsUpdate.forEach((item) => {
          if (item._id === action.payload) {
            item.viewCount++;
          }
        });
      }
      return {
        ...state,
      };

    case "LIKE_UNLIKE":
      return {
        ...state,
        influencerPageLoadedData: {
          ...state.influencerPageLoadedData,
          posts: [...action.payload],
        },
      };
    case "PUTTING_POST":
      return {
        ...state,
        influencerPageLoadedData: {
          ...state.influencerPageLoadedData,
          posts: [...action.payload],
        },
      };
    case "REMOVE_PROFILE_IMAGE":
      return {
        ...state,
        userProfileImage: "",
      };

    case "LOGOUT":
      return {
        userType: "",
        userProfileImage: "",
        userBio: "",
        email: "",
        password: "",
        username: "",
        name: "",
        instagram: "",
        facebook: "",
        twitter: "",
        linkedin: "",
        addMoreLinks: [],
        checkUsername: {},
        createpagecheck: false,
        isuserAlreadyRegistered: false,
        social: false,
        accessToken: "",
        refreshToken: "",
        signupCompleted: false,
        isLoggedIn: false,
        userLoggedIn: false,
        influencerPageLoadedData: {
          username: "",
          posts: [],
        },

        subscriptionPlansOfCurrentInfluencer: [],
      };
    case "USER_LOGOUT":
      return {
        ...state,
        userType: "",
        email: "",
        accessToken: "",
        refreshToken: "",
        userLoggedIn: false,
        isLoggedIn: false,
        id: "",
        // added these two lines for testing
        subscriptionPlansOfCurrentInfluencer: [],
      };
    default:
      return { ...state };
  }
};

export default userReducer;
