import { signInWithPopup } from "@firebase/auth";
import { Button, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userSignInSignUpAPi } from "../../Api/InfluencerProfileApis";
import Snackbarpopup from "../../Common/SnackBarPopup";
import ForegetPassword from "../../Pages/ForegetPassword";
import { auth, provider } from "../../Utils/firebase";
import setAuthToken from "../../Utils/setAuthToken";
import { validateEmail, validatePassword } from "../../Utils/validation";
import ButtonField from "./ButtonField";
import { ReactComponent as GoogleIcon } from "../../assets/icons/GoogleIcon.svg";
import "./SigninCard.css";
import { useQuery } from "../../Utils/hooks";

function SigninCard({ close, showTitle = true }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formType, setFormType] = useState("LOGIN");

  const handleSignin = async (gAuthObj) => {
    Snackbarpopup("Please wait...", "warning", dispatch);
    let userData = {};
    if (gAuthObj) {
      userData = {
        email: gAuthObj.email,
        password: gAuthObj.password,
        influencerUsername: state.userReducer.influencerPageLoadedData.username,
      };
    } else {
      userData = {
        email,
        password,
        influencerUsername: state.userReducer.influencerPageLoadedData.username,
      };
    }
    const response = await userSignInSignUpAPi(userData);
    if (response.data.status) {
      localStorage.setItem(
        "auth-token",
        JSON.stringify({
          accessToken: response.data.savedUserData.accessToken,
          refreshToken: response.data.savedUserData.refreshToken,
        })
      );
      setAuthToken();
      let userData = {
        userType: response.data.savedUserData.userType,
        email: response.data.savedUserData.email,
        accessToken: response.data.savedUserData.accessToken,
        refreshToken: response.data.savedUserData.refreshToken,
        id: response.data.savedUserData.id,
        isLoggedIn: false,
        influencer: response.data.savedUserData.influencer,
        subscriptionDetails: response.data.savedUserData.subscriptionDetails,
        user: response.data.savedUserData.user,
        userLoggedIn: true,
        logoutTimer: Date.now(),
      };
      dispatch({
        type: "USER_LOGIN",
        payload: {
          ...userData,
        },
      });
      localStorage.setItem("user", JSON.stringify(userData));

      dispatch({
        type: "DEAFULT_USER_LOGIN_REDIRECT",
      });
      Snackbarpopup(response.data.message, "success", dispatch);
      close();
    } else {
      Snackbarpopup(response.data.message, "warning", dispatch);
    }
  };

  const signInWithGoogle = async (e) => {
    const data = await signInWithPopup(auth, provider);
    let gAuthObject = {
      email: data.user.email,
      password: data.user.uid,
      social: true,
    };
    handleSignin(gAuthObject);
  };

  const query = useQuery();
  const viewType = query?.get("view-type");

  useEffect(() => {
    if (viewType === "reset-password") {
      setFormType("RESET");
    }
  }, [viewType]);

  return (
    <>
      {showTitle && <Typography className="profile-heading">Official.me</Typography>}
      {formType === "LOGIN" ? (
        <div className="signin-content">
          <div className="modal-heading">
            <h3>Sign in/Sign up</h3>
          </div>
          {/* Email  */}

          <TextField
            label={"Username/ Email address"}
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            fullWidth
          />

          {/* Password */}
          <TextField
            label={"Password"}
            type={"password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            fullWidth
          />
          <Link className="recover_passowrd" onClick={() => setFormType("FORGET")}>
            Forget password ?
          </Link>
          {/* Sign in Button */}
          <ButtonField
            buttonName={"Sign in/Sign up"}
            handleClick={() => {
              if (!validatePassword(password)) {
                Snackbarpopup(
                  "Password should have atleast 8 characters, 1 special character, 1 capital alphabet, 1 small alphabet and 1 digit.",
                  "warning",
                  dispatch
                );
              } else if (!validateEmail(email)) {
                Snackbarpopup("Please check your email.", "warning", dispatch);
              } else {
                handleSignin();
              }
            }}
          />
          <p className="signin-card__text">
            By signing in you agree to our terms of use and privacy policy
          </p>

          <Typography align="center">OR</Typography>

          {/* Sign in with google */}
          <Button
            startIcon={<GoogleIcon />}
            variant="outlined"
            onClick={() => {
              if (state.userReducer.userType !== "influencer") {
                signInWithGoogle();
              } else {
                Snackbarpopup("You are already logged in as an Influencer", "warning", dispatch);
              }
            }}
          >
            Sign in with Google
          </Button>
        </div>
      ) : (
        <ForegetPassword callback={setFormType} />
      )}
    </>
  );
}

export default SigninCard;
